<template>
  <div class="gallery-area">
    <cool-light-box
      :items="slideList"
      :index="index"
      :effect="'fade'"
      @close="index=null"
    />
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xxl-2">
      <div class="col  order-1">
          <div class="row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xxl-2 images-wrapper">
              <div
                class="col image"
                v-for="(img, imgIdx) in slideList.slice(0, 4)"
                :key="imgIdx"
                @click="index = imgIdx"
              >
                <a href="#" data-fancybox="group">
                  <figure class="gallery-two d-flex">
                      <img :src="img" :alt="hotelName" :title="hotelName || ''" />
                      <div class="show-img d-flex align-items-center justify-content-center" v-if="imgIdx===2 && slideList.length>5">
                        <h5>{{`+${(slideList.length - 5)} Photos`}}</h5>
                      </div>
                  </figure>
                </a>
              </div>
          </div>
      </div>
      <div class="col order-2 images-wrapper">
        <div
          class="col image px-0"
          :key="5"
          @click="index = 4"
        >
          <a href="#" data-fancybox="group">
            <figure class="gallery-one d-flex">
              <img :src="slideList[4]" alt="gallery-image" title="" />
            </figure>
          </a>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import TemplateProductSlider from './TemplateProductSlider';

export default {
  mixins: [TemplateProductSlider],
  components: {
    CoolLightBox,
  },
  data() {
    return {
      index: null,
    };
  },
};
</script>

<style scoped>
  .gallery-area .gallery-one{
      height: 549px;
      overflow: hidden;
      border-radius: 5px;
  }
  .gallery-area .gallery-one img{
      width: 100%;
      object-fit: cover;
  }
  .gallery-area .gallery-two {
      height: 262px;
      overflow: hidden;
      border-radius: 5px;
      margin: 0 0 24px;
      position: relative;
  }
  .gallery-area .gallery-two img{
      width: 100%;
      object-fit: cover;
      position: relative;
      z-index: 0;
  }
  .gallery-area .gallery-two .show-img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 70%);
  }
  .gallery-area .gallery-two .show-img h5{
      color: #ffffff;
      padding: 0;
      margin: 0;
      font-size: 24px;
      font-weight: 400;
  }

  @media(max-width: 1399px) {
    .gallery-area .gallery-one {
        height: 503px;
    }
    .gallery-area .gallery-two {
        height: 240px;
    }
  }

  @media (max-width: 1199px){
    .gallery-area .gallery-one {
        height: 370px;
    }
    .gallery-area .gallery-two {
        height: 173px;
    }
  }

  @media (max-width:991.98px){
    .gallery-area .gallery-one {
        height: 280px;
    }
    .gallery-area .gallery-two {
        height: 128px;
    }
    .gallery-area .gallery-two .show-img h5 {
        font-size: 14px;
    }
  }

  @media (max-width:639.98px){
    .gallery-area .gallery-one {
        height: 180px;
    }
    .gallery-area .gallery-two {
        height: 78px;
    }
  }
</style>
